<template>
  <div class='container'>
    <v-container grid-list-md text-xs-center>
      <v-layout row wrap class='echartsMap'>
        <v-flex xs4 lg4>
          <IEcharts
            :option="optionMap"
            theme="macarons"
            @ready="onReady"
            @click="onClickMap"/>
        </v-flex>
        <v-flex xs4 lg4>
           <IEcharts
           :option='optionWordcloud'
           @ready='onReady'
           @click="onClickWordCloud"/>
        </v-flex>
        <v-flex xs4 lg4>
          <IEcharts
            :option="optionPie"
            @ready="onReady"
            @click="onClickPie"/>
        </v-flex>
      </v-layout>
      <v-layout row wrap class='echartsMap'>
        <v-flex xs12 lg12>
          <IEcharts
           :option="optionBar"
           @ready="onReady"
           @click="onClickBar"/>
        </v-flex>
      </v-layout>
      <v-layout row wrap class='echartsMap'>
        <v-flex xs12 lg12>
           <IEcharts
             :option="optionBarAll"
             @ready="onReady"
             @click="onClickBarAll"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
  .echartsMap {
      height: 600px;
  }
  .echartsWordCloud {
      width: 400px;
      height: 400px;
  }
  .echartsPie {
      width: 400px;
      height: 400px;
  }
  .echartsBar {
      width: 400px;
      height: 400px;
  }
  .echartsBarAll {
      width: 400px;
      height: 400px;
  }
</style>
<script>
import axios from 'axios'
import Material from 'vuetify/es5/util/colors'
import IEcharts from 'vue-echarts-v3/src/full.js'
import 'echarts-wordcloud'
import Thailand from '@/assets/thailand_map.json'
import 'echarts/theme/macarons.js'
export default {
  components: {
    IEcharts,
  },
  data: () => ({
    optionMap: {
      tooltip: {
        trigger: 'item',
        formatter: 'จังหวัด {b} <br/> รายได้ {c}'
      },
      series: [{
        name: 'แผนที่',
        type: 'map',
        map: 'thailand',
        roam: true,
        // selectedMode : 'multiple',
        itemStyle: {
          normal: {
            label: {
              show: true
            },
            areaColor: '#FF0000'
          },
          emphasis: {
            label: {
              show: true
            },
            color: 'blue',
            areaColor: '#00FF00'
          }
        },
        label: {
          normal: {
            show: false
          },
          emphasis: {
            label: {
              show: false
            },
            color: 'black'
          },
        },
        data: [],
      }],
    },
    optionPie: {
      title: {
        text: 'รายได้',
        subtext: 'แยกตามจังหวัด',
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: 'จังหวัด {b}<br/>รายได้ {c} ({d}%)'
      },
      legend: {
          // orient: 'vertical',
          // top: 'middle',
        bottom: 10,
        left: 'center',
      },
      series: [
        {
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          selectedMode: 'single',
          data: []
        }
      ],
    },
    optionBar: {
      title: {
        text: '',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: []
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: []
    },
    optionBarAll: {
      title: {
        text: '',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: []
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: []
    },
    color: Material,
    myColors: ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef'],
    optionWordcloud: {
      tooltip: {},
      series: [
        {
          type: 'wordCloud',
          gridSize: 2,
          sizeRange: [12, 50],
          rotationRange: [-90, 90],
          shape: 'pentagon',
          width: 600,
          height: 400,
          drawOutOfBound: true,
          textStyle: {
            normal: {
              color: function () {
                return (
                  'rgb(' +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                  ].join(',') +
                  ')'
                )
              }
            },
            emphasis: {
              shadowBlur: 10,
              shadowColor: '#333'
            }
          },
          data: [
            {
              name: 'Sam S Club',
              value: 10000,
              textStyle: {
                normal: {
                  color: 'black'
                },
                emphasis: {
                  color: 'red'
                }
              }
            },
            {
              name: 'Macys',
              value: 6181
            },
            {
              name: 'Amy Schumer',
              value: 4386
            },
            {
              name: 'Jurassic World',
              value: 4055
            },
            {
              name: 'Charter Communications',
              value: 2467
            },
            {
              name: 'Chick Fil A',
              value: 2244
            },
            {
              name: 'Planet Fitness',
              value: 1898
            },
            {
              name: 'Pitch Perfect',
              value: 1484
            },
            {
              name: 'Express',
              value: 1112
            },
            {
              name: 'Home',
              value: 965
            },
            {
              name: 'Johnny Depp',
              value: 847
            },
            {
              name: 'Lena Dunham',
              value: 582
            },
            {
              name: 'Lewis Hamilton',
              value: 555
            },
            {
              name: 'KXAN',
              value: 550
            },
            {
              name: 'Mary Ellen Mark',
              value: 462
            },
            {
              name: 'Farrah Abraham',
              value: 366
            },
            {
              name: 'Rita Ora',
              value: 360
            },
            {
              name: 'Serena Williams',
              value: 282
            },
            {
              name: 'NCAA baseball tournament',
              value: 273
            },
            {
              name: 'Point Break',
              value: 265
            }
          ]
        }
      ]
    },
    file: '',
    active: null,
    sheets: [],
    pagination: {
      sortBy: '0',
      rowsPerPage: 100
    },
  }),
  computed: {
  },
  created: function () {
    IEcharts.registerMap('thailand', Thailand)
    this.retrieveCustomsWordCloud()
  },
  methods: {
    onReady (instance, ECharts) {
      console.log(instance, ECharts)
    },
    onClickMap (event, instance, ECharts) {
      console.log(arguments)
      this.retrieveCustomsByProvince(arguments[0].data.province)
    },
    onClickWordCloud (event, instance, ECharts) {
      console.log(arguments)
      this.retrieveCustomsByProvince(arguments[0].data.province)
    },
    onClickPie (event, instance, ECharts) {
      console.log(arguments)
      this.retrieveCustomsByProvince(arguments[0].data.province)
    },
    onClickBar (event, instance, ECharts) {
      console.log(arguments)
      // alert(this.optionBar.data[arguments[0].dataIndex].province)
      var provice = this.optionBar.data[arguments[0].dataIndex].province
      this.$swal({type: 'success', title: provice})
    },
    onClickBarAll (event, instance, ECharts) {
      console.log(arguments)
      // alert(this.optionBarAll.data[arguments[0].dataIndex].province)
      var provice = this.optionBarAll.data[arguments[0].dataIndex].province
      this.$swal({type: 'success', title: provice})
    },
    onMapClick: function (attr) {
      this.retrieveCustoms(attr.mapId, attr.title)
    },
    wordClickHandler (name, value, vm) {
      console.log('wordClickHandler', name, value, vm)
      this.retrieveCustomsWordCloud()
    },
    retrieveCustoms (provinceCode, proviceName) {
      axios.post(process.env.VUE_APP_API + '/excel/retrieveCustoms', {
        province: provinceCode
      })
        .then(response => {
          if (response.data.isSuccess) {
            var attributeName = response.data.data[0].province
            var amount = response.data.data[0].sum_income

            this.$notify({
              group: 'map',
              title: 'Information',
              text: `จังหวัด ${proviceName} ${attributeName} ${amount}`
            })
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveCustomsWordCloud () {
      axios.post(process.env.VUE_APP_API + '/excel/retrieveCustoms', {
        province: '%'
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.optionWordcloud.series[0].data = []
            this.optionPie.series[0].data = []
            this.optionBarAll.data = []
            this.optionBarAll.xAxis[0].data = []
            this.optionBarAll.series = []
            this.optionBarAll.series.push({name: 'อากรขาเข้า', type: 'bar', data: []})
            this.optionBarAll.series.push({name: 'อากรขาออก', type: 'bar', data: []})
            this.optionBarAll.series.push({name: 'ค่าธรรมเนียม', type: 'bar', data: []})
            this.optionBarAll.series.push({name: 'รวมรายได้ศุลกากร', type: 'bar', data: []})

            for (let i = 0; i < response.data.data.length; i++) {
              this.optionBarAll.data.push({province: response.data.data[i].province})
              this.optionBarAll.xAxis[0].data.push(response.data.data[i].province_name)
              this.optionBarAll.series[0].data.push(response.data.data[i].sum_import_duty)
              this.optionBarAll.series[1].data.push(response.data.data[i].sum_export_duty)
              this.optionBarAll.series[2].data.push(response.data.data[i].sum_fee)
              this.optionBarAll.series[3].data.push(response.data.data[i].sum_income)

              this.optionWordcloud.series[0].data.push({name: response.data.data[i].province_name, province: response.data.data[i].province, value: response.data.data[i].sum_income})
              this.optionPie.series[0].data.push({name: response.data.data[i].province_name, province: response.data.data[i].province, value: response.data.data[i].sum_income})
              var found = Thailand.features.find(function (element) {
                return element.properties.hasc === response.data.data[i].province
              })
              if (typeof found !== 'undefined') {
                this.optionMap.series[0].data.push({name: found.properties.name,
                  value: response.data.data[i].sum_income,
                  province: found.properties.hasc,
                  sum_import_duty: response.data.data[i].sum_import_duty,
                  sum_export_duty: response.data.data[i].sum_export_duty,
                  sum_fee: response.data.data[i].sum_fee,
                  sum_income: response.data.data[i].sum_income,
                  selected: true})
              }
            }

            const cloneOptionMapData = [...this.optionMap.series[0].data]
            for (let value of Thailand.features) {
              var found1 = cloneOptionMapData.find(function (element) {
                return element.province === value.properties.hasc
              })
              if (typeof found1 === 'undefined') {
                this.optionMap.series[0].data.push({name: value.properties.name,
                  value: 0,
                  province: value.properties.hasc,
                  sum_import_duty: 0,
                  sum_export_duty: 0,
                  sum_fee: 0,
                  sum_income: 0,
                  selected: false})
              }
            }
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveCustomsByProvince (province) {
      axios.post(process.env.VUE_APP_API + '/excel/retrieveCustoms', {
        province: province
      })
        .then(response => {
          if (response.data.isSuccess) {
            for (let i = 0; i < response.data.data.length; i++) {
              this.optionBar.data = []
              this.optionBar.data.push({province: response.data.data[i].province})
              this.optionBar.xAxis[0].data = []
              this.optionBar.xAxis[0].data.push(response.data.data[i].province_name)
              this.optionBar.series = []
              this.optionBar.series.push({name: 'อากรขาเข้า', type: 'bar', data: []})
              this.optionBar.series.push({name: 'อากรขาออก', type: 'bar', data: []})
              this.optionBar.series.push({name: 'ค่าธรรมเนียม', type: 'bar', data: []})
              this.optionBar.series.push({name: 'รวมรายได้ศุลกากร', type: 'bar', data: []})
              this.optionBar.series[0].data.push(response.data.data[i].sum_import_duty)
              this.optionBar.series[1].data.push(response.data.data[i].sum_export_duty)
              this.optionBar.series[2].data.push(response.data.data[i].sum_fee)
              this.optionBar.series[3].data.push(response.data.data[i].sum_income)
            }
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
  }
}
</script>
